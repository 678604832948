<!-- eslint-disable vue/no-v-html -->
<template>
	<v-layout v-if="companies" column fill-height>
		<v-flex v-show="!mini" shrink px-3>
			<v-text-field v-model="companiesSearch" hide-details single-line clearable :label="$t('actions.search')" prepend-inner-icon="search"></v-text-field>
		</v-flex>
		<!-- SAFARI BUG FIX : DO NOT REMOVE height: 0 ! -->
		<v-flex grow scroll-y style="height: 0">
			<v-list :dense="mini">
				<v-subheader v-t="'videoconference.internal'" />
				<v-list-tile :value="selectedVideoConferenceRoom == 'internal'" @click="selectVideoConference(null)">
					<v-list-tile-avatar v-if="mini">
						<v-tooltip bottom>
							<template v-slot:activator="{ on }">
								<v-avatar size="2.5em" class="pointer" color="primary" v-on="on">
									<span class="white--text" v-text="selectedAccountingFirm.abbreviation" />
								</v-avatar>
							</template>
							<span v-text="selectedAccountingFirm.name" />
						</v-tooltip>
					</v-list-tile-avatar>
					<v-list-tile-avatar v-else>
						<v-avatar size="2.5em" class="pointer" color="primary">
							<span class="white--text" v-text="selectedAccountingFirm.abbreviation" />
						</v-avatar>
					</v-list-tile-avatar>
					<v-list-tile-content v-show="!mini">
						<v-list-tile-title class="text-truncate" v-text="selectedAccountingFirm.name" />
					</v-list-tile-content>
				</v-list-tile>
			</v-list>
			<v-subheader v-t="'videoconference.companieslist'" />
			<v-divider v-if="mini" />
			<w-virtual-list :items="companyList" :dense="mini">
				<template v-slot:default="{ item: company, index }">
					<v-list-tile
						avatar
						:value="selectedVideoConferenceRoom === company.id"
						@click="selectVideoConference(company)"
						@mouseover="hover = index"
						@mouseout="hover = null"
					>
						<v-list-tile-avatar v-if="mini">
							<v-tooltip bottom>
								<template v-slot:activator="{ on }">
									<v-avatar size="2.5em" class="pointer" color="primary" v-on="on">
										<v-flex white--text>{{ company.abbreviation }}</v-flex>
									</v-avatar>
								</template>
								<span v-text="company.companyHtml" />
							</v-tooltip>
						</v-list-tile-avatar>
						<v-list-tile-avatar v-else>
							<v-avatar size="2.5em" class="pointer" color="primary">
								<v-flex white--text>{{ company.abbreviation }}</v-flex>
							</v-avatar>
						</v-list-tile-avatar>
						<v-list-tile-content v-show="!mini">
							<v-list-tile-title>
								<v-flex v-if="hover !== index" text-truncate v-html="companyHtml(company)" />
								<v-tooltip v-else bottom>
									<template v-slot:activator="{ on }">
										<v-flex v-on="on" v-html="companyHtml(company)" />
									</template>
									<v-flex text-truncate v-text="company.companyHtml" />
								</v-tooltip>
							</v-list-tile-title>
						</v-list-tile-content>
					</v-list-tile>
				</template>
			</w-virtual-list>
		</v-flex>
	</v-layout>
</template>

<script>
import { mapState } from 'vuex'
import VideoConferenceModuleGuard from '@/mixins/ModulesGuards/VideoConference/VideoConferenceModuleGuard'

export default {
	name: 'VideoConferencesList',
	mixins: [VideoConferenceModuleGuard],
	data: function () {
		return {
			mini: false,
			hover: null,
			companiesSearch: '',
			selectedVideoConferenceRoom: null
		}
	},
	computed: {
		...mapState({
			selectedAccountingFirm: state => state.accountingFirm.selected,
			companies: state => state.company.list,
			selectedCompany: state => state.company.selected,
			isAccountant: state => state.auth.isAccountant
		}),
		enrichedCompanies: function () {
			return this.companies.map(company => {
				const enrichedCompany = { ...company }
				enrichedCompany.companyHtml = company.client_code ? `${company.company} (${company.client_code})` : company.company
				return enrichedCompany
			})
		},
		companyList: function () {
			return this.enrichedCompanies.filter(company => company.company.toLowerCase().indexOf(this.companiesSearch.trim().toLowerCase()) !== -1)
		},
		selectedCompanyId: function () {
			if (this.$parent?.vendorId) {
				return this.$parent.vendorId
			}
			return null
		}
	},
	watch: {
		mini: {
			deep: true,
			handler: function () {
				this.appEventBus.emit(this.appEvents.VIRTUAL_SCROLL_RESIZE)
			},
			immediate: true
		}
	},
	mounted: function () {
		this.selectedVideoConferenceRoom = this.vendorId
	},
	methods: {
		getAppEventsActionsMapping: function () {
			return [
				{ event: this.appEvents.SIDEBAR_MINIMIZED, action: this.onSidebarMinimized },
				{ event: this.appEvents.SIDEBAR_MAXIMIZED, action: this.onSidebarMaximized }
			]
		},
		companyHtml: function (company) {
			let result = ''
			if (company) {
				result = this.isAccountant && company.client_code ? `${company.company} (${company.client_code})` : company.company
				if (this.companiesSearch.trim().length > 0) {
					let start = result.toLowerCase().indexOf(this.companiesSearch.trim().toLowerCase())
					let end = start + this.companiesSearch.trim().length
					let begining = result.substring(0, start)
					let middle = result.substring(start, end)
					let ending = result.substring(end, result.length)
					result = begining + '<b>' + middle + '</b>' + ending
				}
			}
			return result
		},
		selectVideoConference: function (company = null) {
			let routeName
			if (company) {
				this.selectedVideoConferenceRoom = company.id
				routeName = 'videoconference'
			} else {
				this.selectedVideoConferenceRoom = 'internal'
				routeName = 'videoconference-internal'
			}
			this.appService.goTo({ name: routeName, params: { accounting_firm_id: this.accountingFirmId, vendor_id: company ? company.id : null } })
		},
		unselectVideoConference: function () {
			this.selectedVideoConferenceRoom = null
			this.appService.goTo({ name: 'videoconference', params: { accounting_firm_id: this.accountingFirmId, vendor_id: null } })
		},
		onSidebarMaximized: function () {
			this.mini = false
		},
		onSidebarMinimized: function () {
			this.mini = true
		}
	}
}
</script>
