<template>
	<w-layout fill-height full-width>
		<VideoConferenceHome v-if="noRoomSelected" />
		<VideoConferenceRoom v-else :context="{ accountingFirmId: accountingFirmId, vendorId: isInternal ? null : vendorId }" />
	</w-layout>
</template>

<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import VideoConferenceModuleGuard from '@/mixins/ModulesGuards/VideoConference/VideoConferenceModuleGuard'
import VideoConferencesList from '@/components/VideoConference/VideoConferencesList'
import HandleSideBarMixin from "@/mixins/SideBar/HandleSideBarMixin";

export default {
	name: 'VideoConference',
	components: {
		VideoConferenceHome: () => {
			return import('@/components/VideoConference/VideoConferenceHome')
		},
		VideoConferenceRoom: () => {
			return import('@/components/VideoConference/VideoConferenceRoom')
		}
	},
	mixins: [
		VideoConferenceModuleGuard,
		HandleSideBarMixin
	],
	data: function () {
		return {
			loading: false
		}
	},
	computed: {
		...mapState({
			companies: state => state.company.list,
			isAccountant: state => state.auth.isAccountant
		}),
		isInternal: function () {
			return this.$route.name == 'videoconference-internal'
		},
		noRoomSelected: function () {
			return !this.isInternal && this.vendorId == null
		}
	},
	created: function () {
		this.init()
	},
	mounted: function () {
		this.checkMediaAccess()
	},
	destroyed: function () {
		this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
	},
	methods: {
		init: function () {
			this.loading = true
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, true)
			this.appEventBus.emit(this.appEvents.CLEAR_SIDEBAR_CONTENT, this)
			if (this.isAccountant) {
				const VideoConferencesListClass = Vue.extend(VideoConferencesList)
				const videoConferencesListComponent = new VideoConferencesListClass({
					parent: this
				})
				this.appEventBus.emit(this.appEvents.SET_SIDEBAR_CONTENT, {
					canMinimize: this.$vuetify.breakpoint.mdAndUp,
					startHidden: this.$vuetify.breakpoint.smAndDown,
					canClose: this.$vuetify.breakpoint.smAndDown,
					title: this.$t('videoconference.lists_title'),
					moduleInstance: videoConferencesListComponent,
					emitter: this
				})
				this.setHasSidebar(true)
				this.setShowSidebar(this.$vuetify.breakpoint.mdAndUp)
			}
			this.appEventBus.emit(this.appEvents.UPDATE_LOADING_STATUS, false)
		},
		checkMediaAccess: function () {
			navigator.mediaDevices.getUserMedia(
				{ video: true, audio: true },
				mediaStream => {
					mediaStream.getTracks().forEach(mediaTrack => mediaTrack.stop())
				},
				() => {}
			)
		}
	}
}
</script>
