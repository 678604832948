// ============================================================================
// VideoConferenceService
// ----------------------
// VideoConference module related services
// ============================================================================

// -------
// Exports
// -------
export default {}
